<template>
    <div class="recomteacher">
        <table>
            <tr>
                <td style="width:100px;">讲师姓名</td>
                <td><el-input style="width:268px;" v-model="recommend_lecturer.name" placeholder="请输入姓名"></el-input></td>
            </tr>
            <tr>
                <td style="width:100px;">讲师职务</td>
                <td><el-input style="width:268px;" v-model="recommend_lecturer.job" placeholder="请填写讲师职务"></el-input></td>
            </tr>
            <tr>
                <td style="width:100px;">讲师单位</td>
                <td><el-input style="width:268px;" v-model="recommend_lecturer.company" placeholder="请输入讲师单位"></el-input></td>
            </tr>
            <tr>
                <td style="width:100px;">联系方式</td>
                <td><el-input style="width:268px;" v-model="recommend_lecturer.phone" placeholder="请填写联系方式"></el-input></td>
            </tr>
            <tr>
                <td style="width:100px;">专业方向</td>
                <td><el-input style="width:268px;" v-model="recommend_lecturer.major" placeholder="请填写专业方向"></el-input></td>
            </tr>
            <tr>
                <td style="width:100px;" valign="top">推荐理由</td>
                <td>
                    <el-input type="textarea" maxlength="200" :show-word-limit="true" rows="6" resize="none" style="width:268px;" v-model="recommend_lecturer.reasons" placeholder="推荐理由200字以内"></el-input>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><button @click="submitrecom" class="cursorP">提交</button></td>
            </tr>
        </table>
    </div>
</template>
<script>
import { recommend_lecturer } from '@/utils/Api/userList'
export default {
    name:'recomteacher',
    data(){
        return{
            recommend_lecturer:{},
        }
    },
    methods:{
        async submitrecom(){
            if(!this.recommend_lecturer.name){
                this.$message.warning('请填写讲师姓名')
                return
            }
            if(!this.recommend_lecturer.job){
                this.$message.warning('请填写讲师职务')
                return
            }
            if(!this.recommend_lecturer.company){
                this.$message.warning('请填写讲师单位')
                return
            }
            if(!this.recommend_lecturer.phone){
                this.$message.warning('请填写讲师联系方式')
                return
            }
            if(!(/^[1]([3-9])[0-9]{9}$/).test(this.recommend_lecturer.phone)){
                this.$message.warning('讲师联系方式填写错误')
                return
            }
            if(!this.recommend_lecturer.major){
                this.$message.warning('请填写讲师专业方向')
                return
            }
            if(!this.recommend_lecturer.reasons){
                this.$message.warning('请填写讲师推荐理由')
                return
            }
            let calldata = await recommend_lecturer(this.recommend_lecturer)
            if(calldata){
                this.recommend_lecturer = {}
                this.$message.success('推荐成功')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.recomteacher{
    table{
        margin-top: 30px;
        margin-left: 50px;
    }
    tr{
        height: 56px;
    }
    button{
        width: 79px;
        height: 33px;
        opacity: 1;
        background: #ff9500;
        border-radius: 2px;
        font-size: 15px;
        color: #FFFFFF;
        float: left;
    }
}
</style>